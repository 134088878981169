import React, { Component } from 'react';
import './contact.css'; // Import the custom CSS

export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div className="row section-head">
          <div className="ten columns">
            <p className="lead">
            Bitte kontaktieren Sie mich über das folgende Kontaktformular.
            </p>
          </div>
        </div>
        <div className="row">
          <aside className="eigth columns footer-widgets">
            <div className="widget">
              
            </div>
          </aside>
        </div>
        <div id="for" className="row">
          <form action="https://formspree.io/f/xqkrenzd" method="post">
            
              <input type="text" name="name" placeholder="Name" required/>
            
              <input type="email" name="mail" placeholder="Email" required />
            
              <textarea name="message" placeholder="Message" required/>
            <input type="submit" value="Send" />
          </form>
        </div>
      </section>
    );
  }
}