import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs.css'; // Import the custom CSS

const Resume = ({ resumeData }) => {
  return (
    <section id="resume">
      <Tabs>
        <TabList>
          <Tab>Bildung</Tab>
          <Tab>Arbeit</Tab>
          <Tab>Fähigkeiten</Tab>
        </TabList>

        <TabPanel>
          <div className="row education">
            <div className="three columns header-col">
              <h1><span>Bildung</span></h1>
            </div>
            <div className="nine columns main-col">
              {resumeData.education && resumeData.education.map((item) => {
                return (
                  <div className="row item" key={item.UniversityName}>
                    <div className="twelve columns">
                      <h3>{item.UniversityName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span> <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em>
                      </p>
                      <p className="abschluss">
                        {item.Achievements}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="row work">
            <div className="three columns header-col">
              <h1><span>Arbeit</span></h1>
            </div>
            <div className="nine columns main-col">
              {resumeData.work && resumeData.work.map((item) => {
                return (
                  <div className="row item" key={item.CompanyName}>
                    <div className="twelve columns">
                      <h3>{item.CompanyName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span> <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em>
                      </p>
                      <p>
                        {item.Achievements}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="row skill">
            <div className="three columns header-col">
              <h1><span>Fähigkeiten</span></h1>
            </div>
            <div className="nine columns main-col">
              <p>{resumeData.skillsDescription}</p>
              <div className="bars">
                <ul className="skills">
                  {resumeData.skills && resumeData.skills.map((item) => {
                    return (
                      <li key={item.skillname}>
                        <span className={`bar-expand ${item.skillname.toLowerCase()}`}></span>
                        <em>{item.skillname}</em>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </section>
  );
}

export default Resume;
